import { Feature, Map as OLMap } from 'ol';

import { Circle as CircleStyle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';

import { Draw, Modify } from 'ol/interaction';
import { LineString, Point, Geometry, Polygon } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { getArea, getLength } from 'ol/sphere';

import { AreaUnits, toReadableArea, toReadableDistance } from 'utils';
import { MapCommand, MapContext } from 'components/map/MapCommands';
import RenderFeature from 'ol/render/Feature';

const customMeasureColors = {
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black70: 'rgba(0, 0, 0, 0.7)',
  white20: 'rgba(255,255,255,0.2)',
  white: 'rgba(255,255,255,1)',
};

const style = new Style({
  fill: new Fill({
    color: customMeasureColors.white20,
  }),
  stroke: new Stroke({
    color: customMeasureColors.black50,
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: customMeasureColors.black70,
    }),
    fill: new Fill({
      color: customMeasureColors.white20,
    }),
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: '14px Calibri,sans-serif',
    fill: new Fill({
      color: customMeasureColors.white,
    }),
    backgroundFill: new Fill({
      color: customMeasureColors.black70,
    }),
    padding: [3, 3, 3, 3],
    textBaseline: 'bottom',
    offsetY: -15,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: customMeasureColors.black70,
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: customMeasureColors.white,
    }),
    backgroundFill: new Fill({
      color: customMeasureColors.black40,
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: customMeasureColors.black70,
    }),
    fill: new Fill({
      color: customMeasureColors.black40,
    }),
  }),
  text: new Text({
    text: 'Drag to modify',
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: customMeasureColors.white,
    }),
    backgroundFill: new Fill({
      color: customMeasureColors.black70,
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: customMeasureColors.white,
    }),
    backgroundFill: new Fill({
      color: customMeasureColors.black40,
    }),
    padding: [2, 2, 2, 2],
    textBaseline: 'bottom',
    offsetY: -12,
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: customMeasureColors.black40,
    }),
  }),
});

const segmentStyles = [segmentStyle];

export enum ShapeType {
  'Polygon' = 'Polygon',
  'LineString' = 'LineString',
}

export enum MeasureUnits {
  'm' = 'm',
  'km' = 'km',
  'ha' = 'ha',
  'auto' = 'auto',
}

const decimalsToShow = 2;

/**
 * This returns a length label with 2 decimal places of the chosen unit or of default units
 * @param line the line geometry drawn on the map
 * @param units specify units to use
 * @returns a readable string
 */
const formatLength = (line?: Geometry, units: MeasureUnits = MeasureUnits.auto): string => {
  if (!line) return '';
  const length = getLength(line);

  switch (units) {
    case MeasureUnits.m:
    case MeasureUnits.km:
      return toReadableDistance(length, units, decimalsToShow) ?? '';
    default:
      return toReadableDistance(length, null, decimalsToShow) ?? '';
  }
};

const formatArea = (polygon?: Geometry, units: MeasureUnits = MeasureUnits.auto) => {
  if (!polygon) return '';
  const area = getArea(polygon);

  switch (units) {
    case MeasureUnits.m:
      return toReadableArea(area, AreaUnits.m2, decimalsToShow) ?? '';
    case MeasureUnits.km:
      return toReadableArea(area, AreaUnits.km2, decimalsToShow) ?? '';
    case 'ha':
      return toReadableArea(area, AreaUnits.ha, decimalsToShow) ?? '';
    default:
      return toReadableArea(area, null, decimalsToShow) ?? '';
  }
};

/**
 * Measures distances by drawing lines and areas by drawing polygons.
 */
export class MeasureTool implements MapCommand {
  private source: VectorSource<Geometry>;

  private layer: VectorLayer<VectorSource<Geometry>>;

  private modify: Modify;

  private draw?: Draw;

  private map?: OLMap;

  private tipPoint?: Geometry;

  /**
   *
   * @param clearPrevious Default: true; If true, everytime a measure is started,
   * it will clear any old measurements points.
   * @param measureType Default: 'LineString'; The Type of geometry to measure with.
   * Supported options are 'LineString' and 'Polygon'
   * @param showSegments Default: true; If true, the line segment measurements will
   * be displayed.
   * @param units Default: 'auto'; What units to display the measurement information in.
   * Supported options are: 'm', 'km', 'ha', 'auto'. 'ha' will use 'auto' for a length
   * measurement and the same for segment lengths. Auto will use m below 1km (1ha for area) and km above it.
   */
  constructor(
    private clearPrevious = true,
    private measureType: ShapeType = ShapeType.LineString,
    private showSegments = true,
    private units: MeasureUnits = MeasureUnits.auto,
  ) {
    this.source = new VectorSource();

    this.layer = new VectorLayer({
      source: this.source,
      style: (feature) => {
        return this.styleFunction(feature, showSegments);
      },
    });

    this.modify = new Modify({ source: this.source, style: modifyStyle });
  }

  /**
   * This function constructs the styles array for the drawing on the map.
   * @param feature the geometry that will be styled
   * @param segments If true, the line segment measurements will be displayed.
   * @param drawType Polygon or LineString
   * @param textAtTip Optional, only used if drawType is LineString
   * @returns
   */
  private styleFunction(
    feature: RenderFeature | Feature<Geometry>,
    segments: boolean,
    drawType?: ShapeType,
    textAtTip?: string,
  ): Style[] {
    const styles = [style];
    const geometry = feature.getGeometry();
    const type = geometry?.getType();
    let point: Point | undefined;
    let label = '';
    let line: LineString | undefined;

    if (!drawType || drawType === type) {
      if (type === ShapeType.Polygon) {
        point = (geometry as Polygon).getInteriorPoint();
        label = formatArea(geometry as Polygon, this.units);
        line = new LineString((geometry as Polygon).getCoordinates()[0]);
      } else if (type === ShapeType.LineString) {
        point = new Point((geometry as LineString).getLastCoordinate());
        label = formatLength(geometry as LineString, this.units);
        line = geometry as LineString;
      }
    }

    if (segments && line) {
      let count = 0;
      line.forEachSegment((a, b) => {
        const segment = new LineString([a, b]);
        const segmentLabel = formatLength(segment, this.units);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText().setText(segmentLabel);
        styles.push(segmentStyles[count]);
        count++;
      });
    }
    if (label) {
      labelStyle.setGeometry(point as Point);
      labelStyle.getText().setText(label);
      styles.push(labelStyle);
    }
    if (textAtTip && type === 'Point' && !this.modify.getOverlay().getSource().getFeatures().length) {
      this.tipPoint = geometry as Geometry;
      tipStyle.getText().setText(textAtTip);
      styles.push(tipStyle);
    }
    return styles;
  }

  /**
   * This function sets up the map settings for displaying/drawing the measurement.
   * It registers the event handlers and styles for the measure tool
   * based on the settings (unit, shapetype, and whether segments are allowed)
   */
  private initDraw() {
    if (!this.map) return;

    const activeTip = 'Click to continue drawing the ' + this.measureType.toLowerCase();
    const idleTip = 'Click to start measuring';
    let tip = idleTip;

    // what geometry will be drawn and what styling will be used for it
    this.draw = new Draw({
      source: this.source,
      type: this.measureType,
      stopClick: true,
      style: (feature) => {
        return this.styleFunction(feature, this.showSegments, this.measureType, tip);
      },
    });

    this.draw.on('drawstart', () => {
      if (this.clearPrevious) {
        // clearPrevious is an internal setting, the user cannot directly control it
        // this code is trying to be flexible for multiple MeasureTool configurations/implementations
        // clear the previously drawn shapes if clearPrevious is set
        // otherwise they can keep the previous measurements onscreen while they draw new ones
        this.source.clear();
      }
      this.modify.setActive(false);
      tip = activeTip;
    });

    this.draw.on('drawend', () => {
      // Setting to undefined is actually allowed
      // @ts-ignore
      modifyStyle.setGeometry(this.tipPoint);
      this.modify.setActive(true);
      this.map?.once('pointermove', () => {
        // Setting to undefined is actually allowed
        // @ts-ignore
        modifyStyle.setGeometry();
      });
      tip = idleTip;
    });

    this.modify.setActive(true); // allow drawing to start
    this.map.addInteraction(this.draw);
  }

  // register this on the map so that it shows
  apply(mapcontext: MapContext) {
    this.map = mapcontext.map;
    this.map.addLayer(this.layer);
    this.map.addInteraction(this.modify);
  }

  /**
   * Start Measuring
   */
  start() {
    this.draw?.setActive(true);
    this.initDraw();
  }

  /**
   * Stop Measuring and stop draw interaction
   */
  stop() {
    this.draw?.setActive(false);
    this.draw?.dispose();
    this.draw = undefined;
  }

  isMeasuring() {
    return this.draw?.getActive() ?? false;
  }

  setUnits(units: MeasureUnits) {
    this.units = units;
    // Update current draw if any
    this.source.changed();
  }

  getUnits() {
    return this.units;
  }

  setMeasureType(type: ShapeType) {
    this.measureType = type;
    if (this.units === MeasureUnits.ha && this.measureType !== ShapeType.Polygon) {
      this.units = MeasureUnits.auto;
    }
    const hadStarted = this.isMeasuring();
    this.stop();
    this.clear();
    if (hadStarted) this.start();
  }

  getMeasureType() {
    return this.measureType;
  }

  clear() {
    this.source.clear();
  }
}

export default MeasureTool;
